import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ElectronService } from 'ngx-electron';
import { VersionBoxService } from 'version-box';
import { Network } from 'network';
import { DatabaseService, RepositoryService } from 'sqlite';
import { ModeVersion } from 'info';
import { ReplicaLanguageService } from './services/replica-language.service';
import { ReplicaTimeService } from 'replica-common';
import { ReplicaBatteryService } from './services/replica-battery.service';
import { ReplicaSocketListenerService } from './services/replica-socket-listener.service';
import { ReplicaIpcService } from './services/replica-ipc.service';
import { ReplicaSocketService } from './services/replica-socket.service';
import { ErrorType } from 'hybrids-config';
import { ReplicaInteropService } from './services/replica-interop.service';


/**
 * Componente que representa la base de la aplicación
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit
{
  environment = environment;
  ModeVersion = ModeVersion;

  constructor(private ref: ElementRef,
              private router: Router,
              private databaseService:DatabaseService,
              private languageService: ReplicaLanguageService,
              private repositoryService: RepositoryService,
              private socketService: ReplicaSocketService,
              private timeService: ReplicaTimeService,
              private batteryService: ReplicaBatteryService,
              private versionBoxService:VersionBoxService,
              private socketListenerService:ReplicaSocketListenerService,
              private interopService:ReplicaInteropService,
              public ipcService: ReplicaIpcService,
              public electronService:ElectronService)
  {
    //inicializamos la aplicación
    this.initialize();
  }

  /**
   * Redimensiona el tamaño de fuente en función del tamaño de la ventana
   * permitiendo que los estilos css basados en medidas relativas (em, rem)
   * se auto-ajusten según cambia el tamaño de la ventana
   */
  @HostListener('window:resize')
  onResize()
  {
    const minWidth = 480,
         minHeight = 320,
         minSize = 5,
         maxSize = 16;

    let windowWidth: number = window.innerWidth,
       windowHeight: number = window.innerHeight,
       width: number,
       height: number,
       size: number;

    if(windowWidth < minWidth) {windowWidth = minWidth;}
    if(windowHeight < minHeight) {windowHeight = minHeight;}

    if(windowWidth > windowHeight)
    {
      width = windowWidth;
      height = windowWidth * minHeight / minWidth;

      if(height > windowHeight)
      {
        height = windowHeight;
        width = windowHeight * minWidth / minHeight;
      }
    }
    else
    {
      height = windowHeight;
      width = windowHeight * minWidth / minHeight;

      if(width > windowWidth)
      {
        width = windowWidth;
        height = windowWidth * minHeight / minWidth;
      }
    }

    size = Math.round(height * minSize / minHeight);

    if(size > maxSize) {size = maxSize;}

    this.ref.nativeElement.style.fontSize = size + 'px';
  }

  /**
   * Se ejecuta cuando se carga por primera vez la aplicación
   */
  ngOnInit(): void
  {
    this.onResize();
  }

  /**
   * Ejecuta los procesos de inicialización de la aplicación
   * y añade las subscripciones necesarias para responder a
   * diversas acciones emitidas desde distintos componentes
   */
  async initialize()
  {
    try
    {
      this.repositoryService.setDatabase(await this.databaseService.openConnection());
      this.languageService.initialize();

      await this.ipcService.initialize();

      this.versionBoxService.initialize(this.ipcService.info.software, this.ipcService.info.hardware);

      this.batteryService.initialize();
      this.timeService.initialize();

      this.interopService.initialize();

      //inicializamos el socket y el listener
      this.socketService.initialize(environment.endPointSocket, environment.device);
      this.socketListenerService.initialize();

      //configuración inicial de la red wifi
      await Network.initialize(this.ipcService.info.ssid!, this.ipcService.info.password!, this.ipcService.localStorageWireless);


      if(this.electronService.isElectronApp) //en aplicaciomnes electron iniciamos el checkeo de redes
        await Network.check();
      else //en aplicaciones web abrimos la conexión socket
        this.socketService.openConnection();
    }
    catch(err)
    {
      this.router.navigate(['/', 'error', ErrorType.UNKNOW]);
    }
  }
}

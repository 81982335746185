import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ElectronService } from 'ngx-electron';
import { ModalModule } from 'modal';
import { ModalBoxButton, ModalBoxClose, ModalBoxEvent, ModalBoxModule } from 'modal-box';
import { DialogBoxConfig, DialogBoxModule } from 'dialog-box';
import { VersionBoxConfig, VersionBoxModule } from 'version-box';
import { SqliteModule } from 'sqlite';
import { WirelessModule } from 'wireless';
import { HYBRIDS_SOCKET_SERVICE_TOKEN, HybridsSocketModule } from 'hybrids-socket';
import { ApplicationType, HybridsConfig } from 'hybrids-config';
import { environment } from 'src/environments/environment';
import { REPLICA_BATTERY_SERVICE_TOKEN, REPLICA_INTEROP_SERVICE_TOKEN, REPLICA_LANGUAGE_SERVICE_TOKEN, ReplicaCommonModule, ReplicaFloatNumberPipe, ReplicaFormatValuePipe, ReplicaNumberToTimePipe, ReplicaParameterToValuePipe } from 'replica-common';
import { REPLICA_CARDIOHELP_SERVICE_TOKEN, ReplicaCardiohelpModule } from 'replica-cardiohelp';
import { REPLICA_NOVALUNG_SERVICE_TOKEN, ReplicaNovalungModule } from 'replica-novalung';
import { ReplicaSimulationCardiohelpService } from './services/replica-simulation-cardiohelp.service';
import { ReplicaSimulationNovalungService } from './services/replica-simulation-novalung.service';
import { ReplicaInteropService } from './services/replica-interop.service';
import { ReplicaBatteryService } from './services/replica-battery.service';
import { ReplicaSocketService } from './services/replica-socket.service';
import { ReplicaLanguageService } from './services/replica-language.service';


//inicializamos los prototype de objetos globales
import { global } from 'common';
import { MenuComponentModule } from './components/menu/menu.component.module';


global.init();

const config = new HybridsConfig({
  me: environment.device,
  endPointSocket: environment.endPointSocket,
  version: environment.version,
  defaultLanguage: environment.defaultLanguage,
  applicationType: ApplicationType.REPLICA
});


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({animated: false}),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    AppRoutingModule,
    ModalModule.forRoot(),
    ModalBoxModule.forRoot(),
    DialogBoxModule.forRoot(new DialogBoxConfig({
      accept: new ModalBoxButton({
        title: 'aceptar',
        event: ModalBoxEvent.ACCEPT
      }),
      cancel: new ModalBoxButton({
        title: 'cancelar',
        event: ModalBoxEvent.CANCEL
      })
    })),
    VersionBoxModule.forRoot(new VersionBoxConfig({
      close: new ModalBoxClose({
        event: ModalBoxEvent.CANCEL,
        icon: './replica-common/assets/images/SVG/close_enabled.svg'
      }),
      image: './replica-common/assets/images/SVG/isotype.svg'
    })),
    SqliteModule.forRoot({file: '../assets/database/replica.json'}),
    HybridsSocketModule.forRoot(),
    WirelessModule.forRoot(),
    ReplicaCommonModule.forRoot(config),
    ReplicaCardiohelpModule.forRoot(),
    ReplicaNovalungModule.forRoot(),
    MenuComponentModule
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    ElectronService,
    ReplicaFloatNumberPipe,
    ReplicaFormatValuePipe,
    ReplicaNumberToTimePipe,
    ReplicaParameterToValuePipe,

    { provide: HYBRIDS_SOCKET_SERVICE_TOKEN, useExisting: ReplicaSocketService },
    { provide: REPLICA_CARDIOHELP_SERVICE_TOKEN, useExisting: ReplicaSimulationCardiohelpService },
    { provide: REPLICA_NOVALUNG_SERVICE_TOKEN, useExisting: ReplicaSimulationNovalungService },
    { provide: REPLICA_INTEROP_SERVICE_TOKEN, useExisting: ReplicaInteropService },
    { provide: REPLICA_BATTERY_SERVICE_TOKEN, useExisting: ReplicaBatteryService },
    { provide: REPLICA_LANGUAGE_SERVICE_TOKEN, useExisting: ReplicaLanguageService },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
     { prefix: './replica-common/assets/i18n/', suffix: '.json' }, //replica common assets
     { prefix: './replica-cardiohelp/assets/i18n/', suffix: '.json' }, //replica cardiohelp assets
     { prefix: './replica-novalung/assets/i18n/', suffix: '.json' }, //replica novalung assets
     { prefix: './keyboard/assets/i18n/', suffix: '.json' }, //keyboard assets
     { prefix: './wireless/assets/i18n/', suffix: '.json' }, //wireless assets
     { prefix: './modal-box/assets/i18n/', suffix: '.json' }, //modal-box assets
     { prefix: './version-box/assets/i18n/', suffix: '.json' } //version-box assets
   ])
 }

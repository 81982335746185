import { Injectable } from "@angular/core";
import { Parameter } from "replica-common";
import { ReplicaInteropService } from "./replica-interop.service";
import { HybridsConfig } from "hybrids-config";
import { TranslateService } from "@ngx-translate/core";
import { EventManagerService } from "event-manager";
import { Router } from "@angular/router";
import { NovalungNoticeService, ReplicaSimulationNovalungBaseService } from "replica-novalung";

@Injectable({
  providedIn: 'root'
})
export class ReplicaSimulationNovalungService extends ReplicaSimulationNovalungBaseService
{
  constructor(router: Router,
    eventManagerService: EventManagerService,
    translateService: TranslateService,
    config: HybridsConfig,
    interopService:ReplicaInteropService,
    novalungNoticeService:NovalungNoticeService)
  {
    super(router, eventManagerService, translateService, config, interopService, novalungNoticeService);
  }
}

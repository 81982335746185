import { EventManagerService } from 'event-manager';
import { Condition, ConditionOperator, Field, Query, RepositoryService, Table } from 'sqlite';
import { Injectable } from "@angular/core";
import { Device, HybridsConfig } from "hybrids-config";
import { SocketAction, SocketMessage } from "hybrids-socket";
import { ReplicaEvent, ReplicaInteropBaseService, SimulatorType } from "replica-common";
import { ReplicaSocketListenerService } from "./replica-socket-listener.service";
import { ReplicaSocketService } from "./replica-socket.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ReplicaInteropService extends ReplicaInteropBaseService
{
  constructor(eventManagerService:EventManagerService,
              private socketListenerService:ReplicaSocketListenerService,
              private socketService:ReplicaSocketService,
              private router:Router,
              private repositoryService:RepositoryService,
              private config:HybridsConfig)
  {
    super(eventManagerService);
  }

  sendData(request: SocketMessage): void
  {
    if(this.socketListenerService.comunicationEnabled)
      this.socketService.send(request.action!, this.socketService.addresses.teacher, request.data, request.id, request.result);
  }

  powerOff()
  {
      this.router.navigate([ '/', 'home' ]);
  }

  setParametersSimulator(simulator: SimulatorType)
  {
    return new Promise<void>((resolve, reject) =>
    {
      try
      {
        //cargamos los parámetros específicos de cada simulador
        let table = new Table('Parameters', [new Field('idSimulatorType', [new Condition(ConditionOperator.EQUAL, simulator)])]);

        this.repositoryService.exeQuery(new Query([table])).then(params =>
        {
          this.parameters = params;

          //cargamos la configuración de alarmas de los parámetros
          table = new Table('ParametersAlarms');
          this.repositoryService.exeQuery(new Query([table])).then(alarms =>
          {
            this.parameters.map(param => param.alarms = alarms.filter((m:any) => m.idParameter === param.idParameter));

            if(this.basal.length === 0)
              this.basal = this.parameters.cloneObject();

            resolve();
            // this.checkForSimulation().then(() => resolve()).catch((err) => {console.log(err);  reject()});
          });
        });
      }
      catch (err)
      {
        reject();
      }
    });
  }


  changeSocketControl(from: Device, to: Device)
  {
    this.config.me = to;
    this.socketListenerService.comunicationEnabled = (to === Device.REPLICA);
    this.socketService.changeDevice(to);
    this.socketService.send(SocketAction.CHANGE_DEVICE, this.socketService.addresses.server, { from, to });
  }

  checkForSimulation()
  {
    return new Promise<void>((resolve, reject) =>
    {
      try
      {
        if(this.socketListenerService.comunicationEnabled)
        {
          //preguntamos al profesor si esta reproduciendo alguna simulacion
          this.socketService.sendAndRetrive(SocketAction.STAGE, this.socketService.addresses.teacher).then(request =>
          {
            //actualizamos los parametros
            if(request.data)
              for (const param of request.data)
                this.eventManagerService.emitEvent(ReplicaEvent.UPDATE_PARAMETER ,param);

            resolve();
          });
        }
        else
        {
          resolve();
        }
      }
      catch (err)
      {
        reject();
      }
    });
  }
}

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Language, ReplicaLanguageBaseService } from "replica-common";
import { Query, RepositoryService, Table } from "sqlite";


@Injectable({
  providedIn: 'root'
})
export class ReplicaLanguageService extends ReplicaLanguageBaseService
{
  constructor(translate: TranslateService,
              private repositoryService:RepositoryService)
  {
    super(translate);
  }


  getLanguages(): Promise<Language[]>
  {
    return this.repositoryService.exeQuery(new Query([new Table('Languages')]));
  }
}

import { EventManagerService } from 'event-manager';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModeVersion } from 'info';
import { ElectronService } from 'ngx-electron';
import { VersionBoxService } from 'version-box';
import { ErrorType } from 'hybrids-config';
import { HybridsMenuEvent } from 'hybrids-menu';
import { ReplicaSocketListenerService } from 'src/app/services/replica-socket-listener.service';
import { ReplicaIpcService } from 'src/app/services/replica-ipc.service';

/**
 * Componente que representa el menu lateral
 */
@Component({
  selector: 'replica-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent
{
  ModeVersion = ModeVersion;

  constructor(private eventManagerService: EventManagerService,
              private versionBoxService:VersionBoxService,
              private router: Router,
              public electronService:ElectronService,
              public ipcService:ReplicaIpcService,
              private socketListenerService:ReplicaSocketListenerService)
  {}

  /**
   * Permite la navegación desde el menú lateral de la aplicación
   * @param path nombre de la página a que se quiere navegar
   */
  navigateTo(path: string)
  {
    this.eventManagerService.emitEvent(HybridsMenuEvent.MENU_CLOSE);

    if(path !== 'help')
    {
      if(path === 'home' && !this.socketListenerService.teacherEnabled)
        this.router.navigate([ '/', 'error', ErrorType.SOCKET ]);
      else
        this.router.navigate(['/', path]);
    }
  }

  /**
   * Cierra la aplicación desde el menú lateral de la aplicación
   */
  closeApp()
  {
    this.eventManagerService.emitEvent(HybridsMenuEvent.MENU_CLOSE);
    this.ipcService.quitApp();
  }

  /**
   * Abre una ventana modal con la versión de software
   */
  openSoftwareVersion()
  {
    this.eventManagerService.emitEvent(HybridsMenuEvent.MENU_CLOSE);
    this.versionBoxService.show();
  }
}

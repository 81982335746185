import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from './menu.component';
import { HybridsMenuModule } from 'hybrids-menu';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    HybridsMenuModule
  ],
  exports: [MenuComponent],
  declarations: [MenuComponent]
})
export class MenuComponentModule {}

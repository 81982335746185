import { Injectable } from "@angular/core";
import { ReplicaSimulationCardiohelpBaseService } from "replica-cardiohelp";
import { ReplicaInteropService } from "./replica-interop.service";
import { HybridsConfig } from "hybrids-config";
import { TranslateService } from "@ngx-translate/core";
import { EventManagerService } from "event-manager";
import { Router } from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class ReplicaSimulationCardiohelpService extends ReplicaSimulationCardiohelpBaseService
{
  constructor(router: Router,
    eventManagerService: EventManagerService,
    translateService: TranslateService,
    config: HybridsConfig,
    interopService:ReplicaInteropService)
  {
    super(router, eventManagerService, translateService, config, interopService);
  }
}


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocketAction, SocketMessage} from 'hybrids-socket';
import { Device, EcmoMode, ErrorType, HybridsConfig } from 'hybrids-config';
import { EventManagerService } from 'event-manager';
import { ReplicaSocketService } from './replica-socket.service';
import { Network } from 'network';
import {  ReplicaEvent, ReplicaSocketListenerBaseService } from 'replica-common';


/**
 * Gestiona las conexiones al socket service
 */
@Injectable({
  providedIn: 'root'
})
export class ReplicaSocketListenerService extends ReplicaSocketListenerBaseService
{
  firstIntent: boolean; //indica si es el primer intento de conexión al socket service

  teacherEnabled = false;
  comunicationEnabled = false;

  constructor(private router: Router,
              private config:HybridsConfig,
              hybridsSocketService:ReplicaSocketService,
              eventManagerService: EventManagerService)
  {
    super(hybridsSocketService, eventManagerService);

    this.firstIntent = true;
  }

  /**
   * Establece las acciones a realizar durante la desconexión del socket
   */
  protected setSocketDisconnected(): void
  {
     //cambiamos el dispositivo
     this.config.me = Device.NONE;

     //mostramos la pantalla de error
     if(this.hybridsSocketService.redirect)
       this.router.navigate([ '/', 'error', ErrorType.SOCKET ]);

    this.firstIntent = false;
  }


  /**
   * Comprueba los dispositivos conectados al socket service
   */
  protected getConnectedDevices(): void
  {
    //Enviamos una petición que espera respuesta
    this.hybridsSocketService.sendAndRetrive(SocketAction.ENABLED_DEVICES_LIST, this.hybridsSocketService.addresses.server).then((response:SocketMessage) =>
    {
      if(response.data.find(m => m.device === Device.TEACHER))
      {
        this.enableTeacher();

        this.hybridsSocketService.sendAndRetrive(SocketAction.ECMO_MODE, this.hybridsSocketService.addresses.teacher).then(resp => setTimeout(() => this.changeCommunication(resp.data), 1000));
      }
      else
      {
        this.disableTeacher();
      }

      if(this.hybridsSocketService.redirect)
        this.router.navigate(['/', 'home']);
    });
  }

  /**
   * Cambia el modo del simulador
   * @param mode Modo del simulador
   */
  changeCommunication(request: any)
  {
    this.comunicationEnabled = (request.data === EcmoMode.REPLICA);

    if(this.comunicationEnabled)
      this.eventManagerService.emitEvent(ReplicaEvent.CHECK_FOR_SIMULATION);
  }

  protected setRetryConnection()
  {
    Network.getNetworkStatus().then(status =>
    {
      if(status)
        this.hybridsSocketService.timeOutRetry = setTimeout(() => this.hybridsSocketService.openConnection(), this.hybridsSocketService.interval);
    });
  }
}

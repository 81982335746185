import { Injectable } from "@angular/core";
import { SocketAction, SocketDataSet } from "hybrids-socket";
import { ReplicaBatteryBaseService, ReplicaTimeService } from "replica-common";
import { Device as CapacitorDevice } from '@capacitor/device';
import { HybridsConfig } from "hybrids-config";
import { TranslateService } from "@ngx-translate/core";
import { EventManagerService } from "event-manager";
import { ReplicaInteropService } from "./replica-interop.service";
import { ReplicaSocketListenerService } from "./replica-socket-listener.service";
import { ReplicaSocketService } from "./replica-socket.service";
import { ElectronService } from "ngx-electron";

@Injectable({
  providedIn: 'root'
})
export class ReplicaBatteryService extends ReplicaBatteryBaseService
{
  constructor(timeService: ReplicaTimeService,
              translateService: TranslateService,
              eventManagerService: EventManagerService,
              config:HybridsConfig,
              interopService:ReplicaInteropService,
              private socketListenerService:ReplicaSocketListenerService,
              private socketService:ReplicaSocketService,
              private electronService:ElectronService)
  {
    super(timeService,
          translateService,
          eventManagerService,
          config,
          interopService);
  }

  setDeviceStats()
  {
    if(this.electronService.isElectronApp)
    {
      CapacitorDevice.getBatteryInfo().then(status =>
      {
        this.batteryLevel = (status.batteryLevel ?? 0) * 100; //devuelve el valor de cero a uno con decimales
        this.batteryPluged = status.isCharging ?? false;

        this.calculate();
      });
    }
  }

  sendBateryStats(stats: SocketDataSet[])
  {
    if(this.socketListenerService.comunicationEnabled)
      this.socketService.send(SocketAction.CHANGE_BATTERY, this.socketService.addresses.teacher, stats);
  }
}
